/* Header.css */

.main-header {
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #141414; /* Dark overlay on the image */
  color: #fff; /* Text color */
  z-index: 9999;
}



.logo-container {
  /* Add any styling for the logo container */
}

.logo {
  width: 100px; /* Adjust the width of the logo */
  height: auto;
  color: #000;
}
.christmas-tree{
  width: 100px; /* Adjust the width of the logo */
  height: auto;
  color: #000;
}

.main-navigation {
  display: flex;
  align-items: center;
}

.main-navigation ul {
  list-style: none;
  display: flex;
}


.main-navigation li {
  font-family: 'Roboto';
  font-size: 24px;
  margin-right: 20px; 
  cursor:pointer;
}


@media (max-width: 1400px) {

  .main-navigation li {
    font-family: 'Roboto';  
    font-size: 18px;
  }
}


.main-navigation a {
  font-size: 18px;
  text-decoration: none;

}

/* Base styles */
ul li a {
  display: block;
  padding: 10px 20px;
  border-radius: 5px; /* Rounded corners for better visuals */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  color: #ffffff; /* Default link color */
  text-decoration: none;
}

/* Active state */
.active-bg {
  color: #ff0000;
  border-bottom: 2px solid;
  border-color: #fff;
  
  font-weight: bold;
}

/* Hover effect for links */
ul li a:hover {
  color: #ff0000;
}
.active-bg-nav{
  color: #ff0000;

  font-weight: bold;
}


/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333; /* Change the background color as needed */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff; /* Change the text color as needed */
  padding: 12px 16px;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}



.menu-icon {
  display: none; /* Hide menu icon by default for larger screens */
  cursor: pointer;
  margin-right: 50px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

/* Hamburger menu transition */
.menu-icon {
  display: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: all 0.4s ease;
}

/* Hamburger to "X" transition */
.bar.top.open {
  transform: rotate(45deg) translate(5px, 5px);
}

.bar.middle.open {
  opacity: 0;
}

.bar.bottom.open {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Menu */
@media (max-width: 954px) {
  .menu-icon {
    display: block;
  }

  .main-navigation ul {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    position: absolute;
    gap: 30px;
    top: 110px;
    left: 150px;
    width: 100%;
    height: 100vh;
    background-color: #141414;
    opacity: 1;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.7s ease, transform 0.7s ease;
  }

  .main-navigation ul.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}


li {
  
  list-style: none; /* Remove default list styling */
}

.switch {
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.switch > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-family: 'Roboto';
    font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #000000;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #000000;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #000000;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  margin: 0 30px;
  padding: 2px;
  width: 97px;
  height: 35px;
  background-color: #000000;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #DB2404;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  border-radius: 52px;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}

.check-toggle-round-flat{
  display: none;

}