

/* Applying bounce-in animation to the banner text */
.contact-banner-text-er {
  margin: 50px;
  font-size: 96px;
  color: white;
  text-align: flex-start;
  font-family: 'Ser Black', sans-serif; /* Ensure the font is loaded correctly */

}

/* Banner section */
.contact-banner-er {
  display: flex;
  height: 400px;
  max-height: 200px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 109px;
  background-image: url('../../../public/bg.gif'); /* Add your background image path here */
  background-size: 720px;
  background-position:center;
  background-repeat: repeat;
  max-height: 423px;
}

/* Text inside the banner */
.contact-banner-text {
  display: flex;
  justify-content: center;
  font-size: 96px;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif; /* Using Ser Black font */
}
.contact-form-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
}
.contact-form-container {
  display: flex;
  max-width: 1720px;
  justify-content: space-between;
  align-items: center;

  background-color: #000000;
  margin: 20px;
  border: 5px solid #953131; /* Solid black border */
  border-radius: 50px; /* Optional: rounded corners */
}


.StyledContactForm{
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 64%;
}
.con-form-section {
   flex-direction: column;
    display: flex;
    flex: 1 1;
    margin: 20px;
    gap: 20px;
}

.contact-img-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-img-container img {
  border-radius: 50px;
  max-width: 100%;
  height: auto;
}

/* Container with flex-direction row and gap */
.cont-3 {

  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}
.cont-3 p {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.cont-3 p svg {
  margin-right: 8px; /* Space between the icon and text */
  width: 28px; /* Set icon size */
  height: 28px;
  color: #ffffff;
  fill: none;

 
}
.get-text {
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px; /* Set max width for better readability */
}

.get-text h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.get-text p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: justify; /* Justify text for better appearance */
}


/* Mobile responsiveness */
@media (max-width: 768px) {
  .contact-banner-er {
    margin-top: 68px;
    justify-content: center; /* Center content on smaller screens */
    background-position: top;
    max-height: 100px; /* Adjust height for mobile */
  }

  .contact-banner-text-er {
    margin: 20px;
    font-size: 38px; /* Reduce font size for smaller screens */
    text-align: center; /* Center text on smaller screens */
  }

  .contact-form-container {
    flex-direction: column; /* Stack the form and image vertically on smaller screens */
    padding: 10px;
    border-radius: 20px;
  }

  .con-form-section, .contact-img-container {
    margin: 10px 0; /* Adjust margins for smaller screens */
  }
  .get-text{
      padding: 15px;
    max-width: 90%;
  }
  .get-text h1 {
    font-size: 2rem;
  }
  .get-text p{
      font-size: 0.9rem
  }
  .cont-3{
    flex-direction: column; /* Stack the items vertically on mobile */
    gap: 10px;
  }
  .cont-3 p {
font-size: 14px;
  }
  .cont-3 p svg{
     width: 18px; /* Reduce icon size for mobile */
    height: 18px
  }

}