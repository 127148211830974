/* Main Section Styling */
#priceplans {
  padding: 50px 20px;
  background-color: #141414; /* Netflix-like dark theme */
}

.services-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Title Styling */
.our-services {

  text-align: center;
  margin-bottom: 40px;
}
.our-services-1 {
  margin-top: 80px;
  text-align: center;
  margin-bottom: 40px;
}

.our-services h2 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #d8d8d8, #c0c0c0, #d8d8d8);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6);
}

.our-services-1 h2 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #d8d8d8, #c0c0c0, #d8d8d8);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6);
}

/* Services Container */
.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content:flex-start;
  margin-bottom: 50px;
}

.services-container h3 {
  width: 100%;
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
  background: linear-gradient(90deg, #d8d8d8, #c0c0c0, #d8d8d8);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6);
}

/* Individual Service Items */
.service-item {
  flex: 1 1 calc(33.333% - 30px); /* 3 items per row with spacing */
  background-color: #1c1c1c; /* Dark card background */
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #c0c0c0; /* Subtle silver text */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
  max-width: 350px;
  overflow: hidden;
}




.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.service-item h4 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #d8d8d8, #c0c0c0, #d8d8d8);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6);
}

.service-item p {
  font-size: 1.125rem;
  color: #c0c0c0;
  margin-bottom: 15px;
  line-height: 1.5;
}

.service-item .price {
  font-size: 1.5rem;
  font-weight: 700;
  color: #f6a800; /* Bright Adobe-style accent */
}

.load-item{

  background-color: #0056b3;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

/* Load More Button */
.load-more {
  background-color: #b7bec6;
  color: #fff;
  align-self: flex-end;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.load-more:hover {
  background-color: #6e87a2;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-item {
    flex: 1 1 calc(50% - 30px); /* 2 items per row */
  }

  
.services-container h3 {
  
  font-size: 1.5rem;
  
}
}

@media (max-width: 480px) {
  .service-item {
    flex: 1 1 100%; /* 1 item per row */
  }

  .our-services h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 1200px) {
  .services-container {
    justify-content: center;
  }
}
