/* ReferralLink.css */

.referral-section {
  display: flex;
  align-items: center;
  margin: 20px;
  overflow: hidden;
}

.referral-links p {
  white-space: nowrap; /* Prevent line breaks */
  margin: 0;
}

.referral-section a {
  margin-right: 10px;
  text-decoration: none;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.button-container button {
  margin-right: 10px;
  border-radius: 50%;
  
}

.reel-animation {
  width: 150px; /* Adjust the width as needed */
  height: 200px; /* Maintain aspect ratio */
  max-height: 200px;
  border-radius: 25px;
  /* animation: reelTransform 0.3s ease-in-out forwards; 
  animation-play-state: paused;  */
}

.reel-animation:hover {
  animation-play-state: running; /* Play the animation on hover */
}

@keyframes reelTransform {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
