/* Container for features and modal styling */
.features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  opacity: 0; /* Start invisible */
  animation: fadeIn 1.5s ease-in-out forwards; /* Fade-in animation */
  animation-delay: 1.5s
}

.features-list {
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 25px;
  justify-content: center;
}
.features-title {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #d8d8d8, #c0c0c0, #d8d8d8);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6);
}
.feature-item {
  background: #222;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  color: #666666;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  perspective: 1000px;
}
.feature-item.clicked {
  animation: click-3d 0.6s ease forwards;
}

/* 3D animation */
@keyframes click-3d {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  color: #c4c4c4;
  font-size: 3rem;
  margin-bottom: 10px;
}

.cart-icon {
  font-size: 1.5rem;
  margin-top: 15px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cart-icon:hover {
  transform: translate3d(0px, -0.982px, 0px) scale(1.0771, 1.0771);
}

/* Modal Styling for Netflix-like sliding effect */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transform: translate3d(0, 100%, 0); /* Starts off-screen (bottom) */
  animation: fadeInOverlay 0.3s ease-in-out forwards;
}

.modal-content {
  background-color: #222;
  color: #666666;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  border-radius: 12px;
  text-align: center;
  transform: translateY(100%); /* Start from the bottom */
  animation: slideUp 0.5s ease-in-out forwards;
  -webkit-background-clip: text;
  font-size: 2.5rem;
  font-weight: bold;
  animation: glow 2s infinite, float 3s ease-in-out infinite;
  z-index: 1000;
  text-shadow: 0px 0px 8px rgba(229, 9, 20, 0.7);
}

@keyframes glow {
  0% {
    text-shadow: 0px 0px 8px rgba(229, 9, 20, 0.7);
  }
  50% {
    text-shadow: 0px 0px 20px rgba(229, 9, 20, 1);
  }
  100% {
    text-shadow: 0px 0px 8px rgba(229, 9, 20, 0.7);
  }
}

/* Floating Effect */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); /* Off-screen */
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); /* Center of the viewport */
  }
}

.modal-content{
  font-size: 24px;
}

/* Close button styling */
.close-modal {
  background-color: #e50914;
  color: #666666;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1rem;
}

.close-modal:hover {
  background-color: #f5c6cb;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
  .features-container{
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  .features-list {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 15px; /* Smaller gap between items */
  }

  .feature-item {
    padding: 15px; /* Reduce padding for smaller screens */
    font-size: 0.9rem; /* Adjust text size */
  }
  .features-title {
    font-size: 2rem;

  }

  .feature-icon {
    font-size: 2.5rem; /* Adjust icon size */
  }

  .cart-icon {
    font-size: 1.2rem; /* Adjust cart icon size */
    margin-top: 10px; /* Reduce margin */
  }

  .modal-content {
    padding: 20px; /* Adjust padding for modal */
    font-size: 0.9rem; /* Adjust text size for modal content */
  }

  .close-modal {
    padding: 8px 15px; /* Smaller button padding */
    font-size: 0.9rem; /* Adjust button font size */
  }

  
}

@media (max-width: 480px) {
  .features-list {
    gap: 10px; /* Further reduce gap */
  }

  .feature-item {
    padding: 10px; /* Further reduce padding */
    font-size: 0.8rem; /* Smaller text size */
  }

  .feature-icon {
    font-size: 2rem; /* Smaller icon size */
  }

  .modal-content {
    font-size: 0.8rem; /* Smaller modal text */
  }

  .close-modal {
    font-size: 0.8rem; /* Smaller button text */
  }
}
