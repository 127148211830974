/* About Section Container */
.about-container {
  padding: 40px 20px;
  background-color: #141414; /* Netflix's signature dark background */
  color: #fff; /* Light text for high contrast */
  text-align: center;
  opacity: 0; /* Start invisible */
  animation: fadeIn 1s ease-in-out forwards; /* Fade-in animation */
  animation-delay: 1s
}

/* About Section Heading */
.about-container h2 {
  font-size: 2.5rem;
  background-image: linear-gradient(135deg, #DFDFDF, #BCBCBC);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-family: 'Roboto';
  text-transform: uppercase;
}

/* About Content Layout */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centering the content for better alignment */
  gap: 20px; /* Space between rows and columns */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* About Item Styling */
.about-item {
  flex: 1 1 calc(50% - 20px); /* Default: two items per row on larger screens */
  border-radius: 15px;
  justify-content: center;
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items: center;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Cinematic shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  padding: 20px;
}

/* Hover Effect for About Items */
.about-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5); /* Deeper shadow on hover */
}

/* Heading and Paragraph Styles */
.about-item h3 {
  font-family: "Roboto";
  font-size: 60px;
  color: red;
  margin-bottom: 10px;
  font-weight: bold;
}

.about-item p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #ffffff; /* Muted text for descriptions */
  line-height: 1.6;
}


/* Responsive Design (Mobile First) */

/* Mobile-first: single column layout */
@media only screen and (max-width: 768px) {
  .about-item {
    flex: 1 1 100%; /* Ensure each item takes full width on smaller screens */
    width: 100%; /* Full width on mobile */
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .about-item p{
  
  }
  .about-item h3{
    font-size: 1;
  }
}

.aos-animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

/* Tablet and Larger Devices: Two items per row */
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .about-item {
    flex: 1 1 calc(50% - 20px); /* Two items per row on medium-sized screens */
  }
}

/* Larger Screens (Desktop) */
@media only screen and (min-width: 1200px) {
  .about-item {
    flex: 1 1 calc(33.333% - 20px); /* Three items per row on large screens */
  }
}
