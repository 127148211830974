/* Landing Section Styling */
/* Landing Section Styling */
.landing-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/bg.gif");
  
  overflow: hidden;
  padding: 60px 20px; /* Extra padding for cinematic feel */
  text-align: start;
  margin-top: 80px;
  color: #fff; /* White text for high contrast */
  position: relative; /* Ensures the 3D scene can be layered above */
  height: 100vh;
}


/* Fade-in animation for landing-content */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Content Container */
.landing-content {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  pointer-events: none;
  font-size: larger;
  font-family: Roboto;

  max-width: 800px;
  margin-left: 40px;
  opacity: 0; /* Start invisible */
  animation: fadeIn 1s ease-in-out forwards; /* Fade-in animation */
  animation-delay: 1s; /* Delay to start animation */
}

/* Heading Styling */
.landing-content h1 {
 
  text-align: start;
  font-size: 3em; /* Larger, cinematic font size */
  margin-bottom: 20px;
  color: #ced4da;
  letter-spacing: 1px; /* Slight letter spacing for emphasis */
}

/* Paragraph Styling */
.landing-content p {
  font-size: 1.4em; /* Larger paragraph font for readability */
  margin-bottom: 30px;
  color: #ddd; /* Muted light text */
  line-height: 1.6; /* Enhanced line spacing for better readability */
}

/* Call-to-Action Button */
.cta-button {
  pointer-events: auto;
  background-color: #e50914; /* Netflix red for the button */
  color: #fff; /* White text for high contrast */
  padding: 15px 30px;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Button shadow for depth */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  background-color: #f40612; /* Brighter red on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Three.js Section Styling */
.three-scene-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%; /* Fill the parent container */
  pointer-events: none; /* Ensure it does not block other elements */
  z-index: -1; /* Position the 3D scene behind the content */
}

/* Responsive Design for Smaller Screens */
@media only screen and (max-width: 600px) {
  .landing-section {
    padding: 30px 10px;
    height: auto;
  }

  .landing-content {
    margin-top: 10px;
    max-width: 100%; /* Content spans full width on small screens */
    margin-left: 0; /* Center-align for smaller devices */
  }

  .landing-content h1 {
    margin-left: 10px;
    margin-top: 120px;
    font-size: 32px; /* Adjust heading size for small screens */
    text-align: start; /* Center-align the text */
  }

  .landing-content p {
    margin: 10px;
    font-size: 16px; /* Smaller font for better fit */
    text-align: flex-start; /* Center-align the paragraph */
  }

  .cta-button {
    padding: 10px 40px; /* Adjust button padding for smaller screens */
    font-size: 1em; /* Adjust font size */
    width: 50%; /* Full-width button */
  }
}
