.company-team {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #f4f4f4;
}

.team-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Set to 3 columns */
  gap: 2rem;
  align-items: stretch;
}

.team-member {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 400px; /* Max width of each item */
  margin: 0 auto; /* Center items if there's extra space */
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.team-member-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.team-member-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.team-member-role {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1rem;
}

.team-member-linkedin {
  font-size: 1rem;
  color: #0a66c2;
  font-weight: 600;
  text-decoration: none;
}

.team-member-linkedin:hover {
  text-decoration: underline;
}

.team-member-links {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.linkedin-logo,
.website-logo,
.behance-logo  {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.team-member-linkedin:hover .linkedin-logo,
.team-member-website:hover .website-logo .behance-logo  {
  transform: scale(1.1);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .team-title {
      font-size: 2rem;
      margin-bottom: 1.5rem;
  }

  .team-grid {
      grid-template-columns: repeat(2, 1fr); /* Display 2 columns on mobile */
      gap: 1.5rem;
  }

  .team-member {
    width: 200px;
      padding: 1.5rem;
  }

  .team-member-name {
      font-size: 1.25rem;
  }

  .team-member-image {
      width: 120px;
      height: 120px;
      object-fit: cover;
      margin-bottom: 1rem;
  }

  .team-member-role {
      font-size: 1rem;
  }

  .linkedin-logo,
  .website-logo,
  .behance-logo  {
      width: 20px;
      height: 20px;
  }
}

@media (max-width: 480px) {
  .team-title {
      font-size: 1.75rem;
      margin-bottom: 1rem;
  }

  .team-grid {
      grid-template-columns: repeat(1, 1fr); /* Display 1 column on very small screens */
      gap: 1rem;
  }

  .team-member {
      padding: 1rem;
  }

  .team-member-name {
      font-size: 1.1rem;
  }

  .team-member-role {
      font-size: 0.9rem;
  }

  .linkedin-logo,
  .website-logo,
  .behance-logo  {
      width: 18px;
      height: 18px;
  }
}
