/* Resources.css - Netflix-Style */
.resources-container {
  display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      opacity: 0;
      animation: fadeIn 1.5s ease-in-out forwards;
      animation-delay: 1.5s;
}

.DS {
  background-image: linear-gradient(to right, #ffffff, #b5b1b0);  -webkit-background-clip: text;
  color: transparent;
}

.resources-list {
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center; 
}

.resource-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px; /* Full width on smaller screens */
  height: 200px; /* Height adjusts automatically */
  aspect-ratio: 16/9; /* Maintains aspect ratio */
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(135deg, #DFDFDF, #BCBCBC);
}


.resource-item:hover {
  transform: scale(1.05); /* Subtle zoom effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.resource-img {
  width: 100%;
  max-width: 300px;
  max-height: 150px;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.resource-item:hover .resource-img {
  animation: rotate-once 1s ease-in-out; /* Trigger animation on hover */
}

/* Define the rotation animation */
@keyframes rotate-once {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


.resource-item h3 {
  position: absolute;
  bottom: 5%;
 
  color: black;
  font-size: 1.2rem; /* Adjusted for small screens */
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.resource-item:hover h3 {
  opacity: 1;
}

.resource-item p {
  position: absolute;
  bottom: 8%;
  left: 5%;
  color: #bbb;
  font-size: 0.9rem; /* Adjusted for small screens */
  line-height: 1.4;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.resource-item:hover p {
  opacity: 1;
}

.resource-item a {
  position: absolute;
  bottom: 15%;
  right: 5%;
  padding: 8px 12px; /* Smaller padding for buttons */
  background-image: linear-gradient(to right, #af8181,  #130707);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 0.9rem; /* Smaller font size for small screens */
  font-weight: bold;
  border-radius: 4px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 2;
}

.resource-item:hover a {
  opacity: 1;
  transform: translateY(0);
}

/* Media Query for Smaller Screens */
/* Media Query for Smaller Screens */
@media only screen and (max-width: 600px) {
  .resources-container {
    padding: 10px; /* Further reduce padding for very small screens */
  }

  .resources-list {
    display: grid; /* Use a grid layout for better control */
    grid-template-columns: repeat(2, 1fr); /* Two items per row */
    gap: 10px; /* Spacing between items */
  }

  .resource-item {
    width: 100%; /* Allow items to fill their grid cells */
    margin: 0; /* Remove unnecessary margin */
  }

  .resource-item img{
    width: 100%;
    height: auto; /* Adjust height to maintain aspect ratio */
  }

  .resource-item h3 {
    margin: 10px;
    font-size: 1rem; /* Adjust font size */
    bottom: 12%; /* Adjust positioning */
  }

  .resource-item p {
    font-size: 0.8rem; /* Smaller text for descriptions */
    bottom: 7%;
  }

  .resource-item a {
    font-size: 0.8rem; /* Adjust font size for buttons */
    padding: 6px 10px; /* Smaller padding for buttons */
    bottom: 2%; /* Adjust positioning */
  }
}
