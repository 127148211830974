/* General Footer Styling */
.footer {
  background-color: #222;
  color: #666666;
  padding: 20px;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-flow: nowrap;
  max-width: 1400px;
  margin: 0 auto;
  gap: 20px; /* Added gap for spacing */
}

/* Top Section */
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.logo-container {
  display: flex;
  justify-content: flex-start ;
  align-items: flex-start;
}

.footer-logo {
  max-width: 150px;
}

.copyright-text {
  font-family: 'Roboto';
  font-size: 0.9rem;
  color: #666666;
  flex: 1; /* Ensures it stretches to fit available space */
  text-align: right;
  order: 2; /* Position for better responsive alignment */
}
.map{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 50px;
  width: 33%;
  align-items: center;
  
}
.google-map {
  border-radius: 25px;
  width: 100%;
  height: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.footer-right {
  display: flex;
  width: 33%;
  align-items: center;
  justify-content: center;
  text-align: left;
}

/* Footer Content */
.location-info,
.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 10px 0;
  gap: 15px;
}

.location-icon,
.contact-icon {
  margin-right: 8px;
  color: #666666;
  gap: 15px;
}

.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: 7ae582;
}

.contact-item {
  margin: 5px 0;
}

/* Mobile Styling */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }
  .map{
    display: flex;
    justify-content: center;

    
    
  }
  .footer-right{

    width: 100%;
  }

  .copyright-text {
    text-align: center;
    margin-top: 10px;
    order: 3; /* Moves copyright to the bottom */
  }

  .footer-content {
    text-align: center; /* Center aligns content for smaller screens */
  }
}
