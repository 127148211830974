/* Basic reset for padding, margin */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  color: #333;
}

/* FAQ Section */
.faq-section {
  padding: 4rem 2rem;
  background-color: #fff;
  color: #333;
  text-align: center;
}

.faq-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #000;
  text-transform: uppercase;
}

/* FAQ List */
.faq-list {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* FAQ Item */
.faq-item {
  border-bottom: 1px solid #000;

  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}



/* FAQ Question */
.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}

.faq-toggle-icon {
  font-size: 1.5rem;
  font-weight: 700;
  transition: transform 0.3s ease;
}

.faq-toggle-icon.active {
  transform: rotate(180deg); /* Rotate the arrow when active */
}

/* FAQ Answer */
.faq-answer p {
  padding: 1.5rem;
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .faq-title {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1rem;
    padding: 1rem;
  }

  .faq-answer {
    padding: 1rem;
  }
}
