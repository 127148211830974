/* Gallery.css */

.gallery-container {
 
  text-align: center;
  overflow: hidden;
  background-color: #141414;;

  opacity: 0; /* Start invisible */
  animation: fadeIn 1.5s ease-in-out forwards; /* Fade-in animation */
  animation-delay: 1.5s

}



.gallery {
  display: flex;

  animation: scrollGallery 150s linear infinite; 
  animation-direction: reverse;
}

.gallery:hover {
  animation-play-state: paused;
}

.gallery-items {
  display: flex;
  width: fit-content;

}

@keyframes scrollGallery {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 8 * 3)); /* Adjust the width of each item and the number of items */
  }
}

.gallery-item {
  width: 300px;
  height: 200px;
 margin: 10px;
  position: relative;
  z-index: 1;
   transition: transform 0.3s ease-in-out;
  text-align: center; /* Center the text within the gallery item */
}

.gallery-item img {
  width: 100%;
 height: 200px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-caption {
  position: absolute;
  top: 50%; /* Adjust as needed to center vertically */
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #fff; /* Optional: Set text color */
  font-weight: bold;
  padding: 10px; /* Optional: Add padding to the caption */
}

.gallery-item:hover {
  transform: scale(1.1);
}


.gallery-item:hover {
  transform: scale(1.1);
}

/* Uni Wait effect */
.gallery-container.waiting {
  cursor: wait ;
  transition: 3s;
}

.gallery-container.waiting .gallery-item:hover {
  transform: scale(1); /* Reset scale on hover when in "waiting" state */
}

.gallery-container.waiting .gallery-items {
  animation-play-state: paused;
}




/* Gallery.css */

.gallery-container-2 {
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.gallery-2 {
  display: flex;
  animation: scrollGalleryOpposite 150s linear infinite; 
}

.gallery-2:hover {
  animation-play-state: paused;
}

.gallery-items-2 {
  display: flex;
  animation-direction: reverse;
  width: fit-content;

}

.gallery-item-2 {
  width: 300px;
  height: 200px;
 margin: 10px;
  position: relative;
  z-index: 1;
   transition: transform 0.3s ease-in-out;
  text-align: center; /* Center the text within the gallery item */
}

.gallery-item-2 img {
  width: 100%;
  height: 200px ;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.gallery-item-2 p {
  margin-top: 10px;
  font-weight: bold;
}

.gallery-item-2:hover {
  transform: scale(1.1);
}

/* Uni Wait effect */
.gallery-container-2.waiting {
  cursor: wait;
}

.gallery-container-2.waiting .gallery-item:hover {
  transform: scale(-1); /* Reset scale on hover when in "waiting" state */
}

.gallery-container-2.waiting .gallery-items {
  animation-play-state: paused;
}

@keyframes scrollGalleryOpposite {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-300px * 8 * 3)); /* Adjust the width of each item and the number of items */
  }
}



.gallery-3 {
  display: flex;

  animation: scrollGallery 150s linear infinite; 
  animation-direction: reverse;
}

.gallery-3:hover {
  animation-play-state: paused;}


  .gallery-items-3 {
    display: flex;
    width: fit-content;
 

  }


  
  .gallery-item-3 {
    width: 300px;
    height: 200px;
   margin: 10px;
    position: relative;
    z-index: 1;
     transition: transform 0.3s ease-in-out;
    text-align: center; /* Center the text within the gallery item */
  }

  .gallery-item-3 img {
    width: 100%;
   height: 200px;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .image-caption-3 {
    position: absolute;
    top: 50%; /* Adjust as needed to center vertically */
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #fff; /* Optional: Set text color */
    font-weight: bold;
    padding: 10px; /* Optional: Add padding to the caption */
  }

  .gallery-item-3:hover {
    transform: scale(1.1);
  }


  .gallery-item-3:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 765px) {

    .gallery-item img,
  .gallery-item-2 img ,
  .gallery-item-3 img {
    width: 100%;

    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}