.partners-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: transform 0.5s ease-in-out;
  overflow: visible; /* Ensure the text does not get affected by transformations */
  position: relative; /* Ensure that the container remains static */
}

.partners-container h2 {
  font-size: 3rem;
  font-family: 'Roboto';
  background-image: linear-gradient(to right, #ffffff, #b5b1b0);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative; /* Keep the heading static */
  overflow: visible;
}

.partners-container p {
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
  position: relative; /* Keep the description text static */
  overflow: visible;
}

.partners-carousel {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  cursor: grab;
  transition: transform 0.5s ease-in-out;
}

.partners-list {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  transition: transform 0.5s ease-in-out;
  position: relative; /* Ensure the list is positioned independently */
}

.partner-item {
  flex: 0 0 auto;
  width: 250px;
  text-align: center;
  background: linear-gradient(135deg, #f0f0f0, #e3e3e3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Apply black shadow initially */
  border-radius: 8px;
  padding: 1rem;
  transition: transform 0.5s ease, filter 0.3s ease, box-shadow 0.3s ease; /* Added box-shadow transition */
  position: relative;
}

.partner-item:hover {
  transform: scale(1.05);
  filter: grayscale(0%) contrast(100%) brightness(100%); /* Show original colors with no grayscale on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.7); /* Darker, stronger black shadow on hover */
}
.logo-part{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 200px;
  cursor: grab;
}

.partner-logo {
  width: 150px;
  max-height: 200px;
  margin-bottom: 0.5rem;
  pointer-events: none;
  
  transition: filter 0.3s ease; /* Added transition for smooth logo color change */
  filter: grayscale(100%) contrast(100%) brightness(80%); /* Make the partner item black and white with shadow effect */
}

/* Add animation on hover for logo */
@keyframes rotateLogo {
  0% {
    transform: rotate(0deg);
    filter: grayscale(100%);
  }
  100% {
    transform: rotate(360deg);
    filter: grayscale(0%) contrast(100%) brightness(100%);
  }
}

.partner-item:hover .partner-logo {
  
  animation: rotateLogo 1s ease-in-out forwards; /* Apply the rotation animation */
}


.partner-item h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.partner-item p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.partner-item:hover .double-click-icon svg {
 
  animation: pulse 1s infinite; /* Trigger the pulse animation */
  transform-origin: center; /* Ensure the pulse scales from the center */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}


.double-click-icon svg {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;
  opacity: 0.7;
  fill: #000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}


@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Mobile sizes */
@media (max-width: 768px) {
  .partners-container h2 {
    font-size: 1.5rem; /* Decrease font size */
    letter-spacing: 1px; /* Reduce letter-spacing */
    margin-bottom: 1.2rem;
  }

  .partners-container p {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .partners-list {
    gap: 0.8rem; /* Reduce the gap between partner items */
    padding: 0.5rem;
  }

  .partner-item {
    width: 150px; /* Reduce the width of partner items */
    padding: 0.8rem; /* Reduce padding */
  }
  .logo-part{
    width: 120px;
    height: 200px;
  }
  .partner-logo{
    width: 100px;
    height: auto;
  }

  .partner-item h3 {
    font-size: 1rem; /* Reduce the font size */
  }

  .partner-item p {
    font-size: 0.8rem; /* Reduce the font size */
  }
}

@media (max-width: 480px) {
  .partners-container h2 {
    font-size: 1.2rem; /* Further decrease font size */
    margin-bottom: 1rem;
  }

  .partners-container p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .partners-list {
    gap: 0.5rem; /* Reduce gap further */
    padding: 0.2rem;
  }

  .partner-item {
    width: 130px; /* Further reduce width */
    padding: 0.6rem; /* Further reduce padding */
  }

  .partner-item h3 {
    font-size: 0.9rem; /* Reduce the font size */
  }

  .partner-item p {
    font-size: 0.7rem; /* Reduce the font size */
  }
}
