form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  input, textarea {
    margin-bottom: 15px;
    color: white;
    padding: 20px;
    font-family: 'Roboto';
    font-size: 14px;
    background-color:unset;
   border: 1px solid #ffffff;
    border-radius: 50px;
  }
  
  button {
    padding: 10px;
    font-size: 16px;
    background-color: #3fa7d6;

    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }
  
  button:hover {
    background-color:#255f85;
    ;
  }
  
  .contacttext p {
    text-align: start;
    font-size: 14px;
    color: rgb(255, 255, 255);
  }
  
  .contacttext {
    margin-bottom: 20px;
    text-align: flex-start;
  }
  
  .contacttext h1 {
    font-size: 54px;
    background-image: linear-gradient(to right, #ffffff, #b5b1b0);    -webkit-background-clip: text;
    color: transparent;
  }
  .contacttext span {
     /* Makes the gradient visible by making the text color transparent */
  }
  