.company-bio {
  margin-top: 80px;
  background: linear-gradient(135deg, #141414, #1c1c1c);
  color: #ffffff;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  padding: 50px 20px;
  overflow: hidden;
}

.bio-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align all content to the start */
  gap: 20px; /* Add spacing between child elements */
}

.bio-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(255, 0, 0, 0.6);
  align-self: center; /* Center the title in the flex container */
  text-align: center; /* Ensure centered text */
}

.bio-description,
.bio-services,
.bio-tech,
.bio-commitment {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0; /* Removed external margin for consistency */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

.bio-services {
  width: 100%; /* Stretch to container width */
}

.bio-services ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; /* Vertical list */
  gap: 10px; /* Spacing between items */
}

.bio-services li {
  display: flex;
  align-items: center;
}

.bio-services li::before {
  content: '🔗';
  margin-right: 10px;
  font-size: 1.2rem;
}

.bio-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 20px;
  background: #e50914;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(229, 9, 20, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.bio-link:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 20px rgba(229, 9, 20, 0.8);
}

.linkedin-icon {
  height: 24px;
  width: 24px;
  margin-left: 8px;
}

/* New Section for Projects */
.bio-projects {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the start */
  gap: 10px;
}

.bio-projects h2 {
  font-size: 1.8rem;
  color: #ffffff;
  margin: 0;
}

.bio-projects p {
  font-size: 1rem;
  color: #d3d3d3;
  margin: 0;
}

.bio-projects ul {
  list-style: disc inside;
  padding-left: 20px;
  margin: 10px 0;
}


.about-container h2{
  display: none;
}