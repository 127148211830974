.privacy-policy-container {
    max-width: 800px;
    margin-top: 0 auto;
    margin-top: 100px;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #666666;
  }
  
  .privacy-policy-container h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #666666;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #666666;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 5px;
  }
  
  .privacy-policy-container p {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 10px;
  }
  
  .privacy-policy-container a {
    color: #666666;
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  
  footer {
    margin-top: 30px;
    text-align: center;
    font-size: 0.9rem;
    color: #666666;
  }
  
  footer p {
    margin: 0;
  }
  

  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-policy-container h1 {
      font-size: 2rem;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.5rem;
    }
  }
  